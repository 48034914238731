import React, { useState, useEffect, useContext } from 'react';
import { Card, Input, Button, Select, Descriptions, message, Space, Empty, Table, Tag, Popover } from 'antd';
import { SearchOutlined, CheckCircleOutlined, EllipsisOutlined } from '@ant-design/icons';
import axios from '../api/index';
import { URLTargets } from '../api/config';

import { Redirect, Link } from "react-router-dom";
import { formatDate } from '../utils/index';
import { useSelector } from 'react-redux'; // 导入useSelector和useDispatch
import surveyIcon from "../images/Icon/survey.svg";


const { Option } = Select;
const { Column } = Table;

interface StoreState {
    searchQuery?: string;
    tag?: string;
    family?: string;
    created_on_start?: string;
    created_on_end?: string;
    scoreStart?: string;
    scoreEnd?: string;
}

const SearchTargetPage = ({ location }) => {
    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState(undefined);
    const [selectedTag, setSelectedTag] = useState(undefined);

    const [ordering, setOrdering] = useState('-analysis__created_on');
    const storeState: StoreState = useSelector(state => state); // 订阅整个store状态

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    useEffect(() => {
        console.log('storeState', storeState)
        const { searchQuery, tag, family, created_on_end, created_on_start, scoreStart, scoreEnd } = storeState
        console.log('查询参数更新', searchQuery, tag, family)
        setSearch(searchQuery)
        setSelectedTag(tag)
        setSelectedFamily(family)
        fetchSearchResults({
            search: searchQuery,
            selectedFamily: family,
            selectedTag: tag,
            current: 1,
            created_on_end,
            created_on_start,
            score_start: scoreStart,
            score_end: scoreEnd,
        })
    }, [storeState]);

    const fetchSearchResults = async (params?) => {
        try {
            const response = await axios.get(URLTargets, {
                params: {
                    search: params?.search ?? search,
                    tag: params?.selectedTag ?? '',
                    family: params?.selectedFamily ?? '',
                    page: params?.current ?? pagination.current,
                    page_size: params?.pageSize ?? pagination.pageSize,
                    ordering: params?.ordering ?? ordering,
                    created_on_start: params?.created_on_start,
                    created_on_end: params?.created_on_end,
                    score_start: params?.score_start,
                    score_end: params?.score_end,
                },
            });
            setResults(response.data.results);
            // setTotal(response.data.count);
            setPagination({
                ...pagination,
                current: params?.current || pagination.current,
                pageSize: params?.pageSize || pagination.pageSize,
                total: response.data.count,
            });
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const handleSorterChange = (pagination, filters, sortedInfo) => {
        console.log('页码table', pagination)
        // setCurrentPage(pagination.current)
        // console.log('set页码table', currentPage)
        const { searchQuery, tag, family, created_on_end, created_on_start, scoreStart, scoreEnd } = storeState
        const { order } = sortedInfo;
        let newOrdering = '-analysis__created_on';
        if (order === 'ascend') {
            newOrdering = 'analysis__created_on';
        } else {
            newOrdering = '-analysis__created_on';
        }
        setOrdering(newOrdering);
        fetchSearchResults({
            search: searchQuery,
            selectedFamily: family,
            selectedTag: tag,
            current: pagination.current,
            pageSize: pagination.pageSize,
            ordering: newOrdering,
            created_on_end,
            created_on_start,
            score_start: scoreStart,
            score_end: scoreEnd,
        });
    };

    const renderTags = (tags: string, color: string) => {
        if (!tags) {
            return '-';
        }
        const tagsArr = tags.split(',')
        const length = tagsArr.length
        const showLength = 4
        const tagNode = (tag, index) => (
            <Tag key={index} color={color}>{tag.trim()}</Tag>
        )
        if (length <= showLength) {
            return tagsArr.map(tagNode)
        } else {
            const content = tagsArr.slice(showLength).map(tagNode)
            return (
                <div>
                    {tagsArr.slice(0, showLength).map(tagNode)}
                    <Popover content={content}>
                        <EllipsisOutlined />
                    </Popover>
                </div>
            )
        }
    };

    return (
        <div style={{ flex: 1 }}>
            <div>

                <div className='result_wrap'>
                    <div className='result_title'>
                        <img src={surveyIcon} alt="" />
                        <div>样本分析</div>
                        <span>({pagination.total})</span>
                    </div>
                    {results.length > 0 ? (
                        <Table
                            dataSource={results}
                            rowKey="analysis_id"
                            pagination={pagination}
                            onChange={handleSorterChange}
                        >
                            <Column
                                className="listcolumn"
                                title="文件名称"
                                dataIndex="target"
                            />
                            <Column
                                className="listcolumn"
                                title="SHA256"
                                dataIndex="sha256"
                                width={250}
                                ellipsis
                                render={(text, record: { analysis_id?: string }) => <Link to={"/detail/" + record.analysis_id}>{text}</Link>}
                            />
                            <Column
                                className="listcolumn"
                                title="家族"
                                dataIndex="family"
                                key="family"
                                render={(text) => renderTags(text, 'volcano')}
                            />
                            <Column
                                className="listcolumn"
                                title="标签"
                                dataIndex="tag"
                                key="tag"
                                width={300}
                                render={(text) => renderTags(text, 'gold')}
                            />
                            <Column
                                className="listcolumn"
                                title="提交时间"
                                dataIndex="submit_time"
                                key="submit_time"
                                render={(text) => formatDate(text)}
                                sorter
                                width={170}
                            />
                            <Column
                                className="listcolumn"
                                title="分析状态"
                                dataIndex="state"
                                key="state"
                                width={120}
                                render={(text) => (
                                    text === 'finished' ? <div style={{ color: 'rgba(0, 0, 0, 0.45)' }}><CheckCircleOutlined style={{ marginRight: '4px' }} />已完成</div> : <div style={{ color: 'rgba(82, 196, 26, 1)' }}><SearchOutlined style={{ marginRight: '4px' }} />正在分析</div>
                                )}
                            />
                            <Column
                                className="listcolumn"
                                title="恶意分数"
                                dataIndex="score"
                                key="score"
                                width={90}
                            />
                        </Table>) : (
                        <Empty description={false} />
                    )}
                </div>

            </div>
        </div>
    );
}

export default SearchTargetPage;