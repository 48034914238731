import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Form, Input, Select, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from '../api/index';
import { URLSetUserRole, URLUserList, URLRolePermissions, URLGetUserRoles } from '../api/config';
import { go } from '../utils';

interface Role {
    id: string;
    name: string;
    description: string;
    permissions: string[];
}
interface User {
    id: number;
    username: string;
    email: string;
    role: Role | null;
    is_active: boolean;
}

interface Permission {
    name: string;
    label: string;
}

const RoleManagement: React.FC = () => {
    const [form] = Form.useForm();
    const [users, setUsers] = useState<User[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [roleId, setRoleId] = useState(undefined);
    const [roles, setRoles] = useState<Role[]>([]);
    const [userId, setUserId] = useState(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        const isStaff = sessionStorage.getItem('isStaff');
        if (!isStaff || isStaff === 'false') {
            return message.info('请用管理员账号登录', 3, () => {
                // 这里写你希望在提示框关闭后执行的代码
                go('/login')
            });
        }
        fetchRoles();
        fetchUserList();
        fetchPermissions();
    }, []);

    const fetchUserList = async () => {
        try {
            const response = await axios.get(URLUserList);
            if (response.status === 200) {
                setUsers(response.data.results);
            }
        } catch (error) {
            console.error('Failed to fetch roles:', error);
            message.error('Failed to fetch roles. Please try again.');
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await axios.get(URLGetUserRoles);
            if (response.status === 200) {
                setRoles(response.data.results);
            }
        } catch (error) {
            console.error('Failed to fetch roles:', error);
            message.error('Failed to fetch roles. Please try again.');
        }
    };

    const fetchPermissions = async () => {
        try {
            const response = await axios.get(URLRolePermissions);
            if (response.status === 200) {
                setPermissions(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch permissions:', error);
            message.error('Failed to fetch permissions. Please try again.');
        }
    };

    const handleEdit = (record) => {
        setRoleId(undefined);
        setUserId(undefined);
        console.log('record.id', record.id)
        setUserId(record.id)
        if (record.role) {
            setRoleId(record.role.id);
        }
        // setRowData(record);
        setIsModalVisible(true);
    };

    const handleSave = async (values: any) => {
        console.log(values);
        try {
            const updatedRole = {
                ...values,
                user_ids: [userId]
            };
            await axios.post(URLSetUserRole, updatedRole);
            setIsModalVisible(false);
            message.success('修改成功');
            fetchUserList();
        } catch (error) {
            console.error('Failed to update role:', error);
            message.error('Failed to update role. Please try again.');
        }
    };

    // useEffect(() => {
    //     if (!isModalVisible) {
    //         form.resetFields();
    //     } else {
    //         setEditingRole(rowData);
    //     }

    //     return () => {
    //         // setEditingRole(rowData);
    //     };
    // }, [isModalVisible, form, rowData]);

    const columns: ColumnsType<User> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '角色名称',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '角色',
            key: 'role',
            render: (_, record) => (
                record.role ? record.role.name : '未设置'
            ),
        },
        {
            title: 'is_active',
            dataIndex: 'is_active',
            key: 'is_active',
            render: (_, record) => (
                String(record.is_active)
            ),
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space>
                    <Button onClick={() => handleEdit(record)}>修改用户角色</Button>
                </Space>
            ),
        },
    ];

    return (
        <div style={{ flex: 1 }}>
            <Table columns={columns} dataSource={users} rowKey={'id'} />
            <Modal
                title="编辑角色"
                visible={isModalVisible}
                onOk={() => form.submit()}
                onCancel={() => setIsModalVisible(false)}
                okText="保存"
                cancelText="取消"
                destroyOnClose={true}
            >
                <Form
                    name="editRoleForm"
                    onFinish={handleSave}
                    preserve={false}
                    form={form}
                    initialValues={{
                        role: roleId || undefined,
                    }}
                >
                    <Form.Item name="role" label="角色">
                        <Select>
                            {roles.map((perm) => (
                                <Select.Option key={perm.id} value={perm.id}>
                                    {perm.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default RoleManagement;