import React, { useState, useEffect } from 'react';
import { Table, Button, message, Space, Form, Input, DatePicker, Select } from 'antd';
import axios from '../api/index';
import { URLDeleteTaskBatch, URLReanalysisBan, URLTargets, URLReanalysisBatch, URLReanalysisUnban, URLTagsFamilies } from '../api/config';
import { formatDate } from '../utils/index';
import { go } from '../utils';
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const TaskManage = () => {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    // 使用 useState 来管理输入框的值
    const [inputValue, setInputValue] = useState('');
    const [createdOnStartValue, setCreatedOnStartValue] = useState('');
    const [createdOnEndValue, setCreatedOnEndValue] = useState('');
    const [rangeValue, setRangeValue] = useState(() => {
        if (createdOnStartValue && createdOnEndValue) {
            return [moment(createdOnStartValue), moment(createdOnEndValue)]
        } else {
            return null
        }
    });
    const [scoreStartValue, setScoreStartValue] = useState('');
    const [scoreEndValue, setScoreEndValue] = useState('');

    // 状态变量用于存储从接口获取的数据
    const [familyOptions, setFamilyOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState(undefined);
    const [selectedTag, setSelectedTag] = useState(undefined);

    useEffect(() => {
        const isStaff = sessionStorage.getItem('isStaff');
        console.log('taskmanager', isStaff)
        if (!isStaff || isStaff === 'false') {
            return message.info('请用管理员账号登录', 3, () => {
                // 这里写你希望在提示框关闭后执行的代码
                go('/login')
            });
        }
        // fetchData();
        axios(URLTagsFamilies)
            .then(response => {
                const data = response.data;
                const families = data.families;
                const tags = data.tags;
                setFamilyOptions(families);
                setTagOptions(tags);
            })
            .catch(error => console.error('Error fetching family options:', error));
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(URLTargets, {
                params: {
                    search: inputValue,
                    tag: selectedTag,
                    family: selectedFamily,
                    page: pagination.current,
                    page_size: pagination.pageSize,
                    // ordering:  ordering,
                    created_on_start: createdOnStartValue,
                    created_on_end: createdOnEndValue,
                    score_start: scoreStartValue,
                    score_end: scoreEndValue,
                },
            });
            setData(response.data.results);
            setPagination({
                ...pagination,
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: response.data.count,
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleDeleteBatch = async () => {
        try {
            await axios.post(URLDeleteTaskBatch, { analysis_ids: selectedRowKeys });
            message.success('批量删除成功');
            fetchData(); // 刷新数据
        } catch (error) {
            console.error('Error deleting batch:', error);
            message.error('批量删除失败');
        }
    };

    const handleReanalyzeBatch = async () => {
        try {
            await axios.post(URLReanalysisBatch, { analysis_ids: selectedRowKeys });
            message.success('批量重新分析成功');
        } catch (error) {
            message.error(error.response.data.message || '批量重新分析失败');
        }
    };

    const handleBanReanalysis = async () => {
        try {
            await axios.post(URLReanalysisBan, { analysis_ids: selectedRowKeys });
            message.success('禁止重新分析成功');
        } catch (error) {
            console.error('Error banning reanalysis:', error);
            message.error('禁止重新分析失败');
        }
    };

    const handleUnbanReanalysis = async () => {
        try {
            await axios.post(URLReanalysisUnban, { analysis_ids: selectedRowKeys });
            message.success('允许重新分析成功');
        } catch (error) {
            console.error('Error unbanning reanalysis:', error);
            message.error('允许重新分析失败');
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const handleSorterChange = (pagination, filters, sortedInfo) => {
        console.log('页码table', pagination)
        setPagination({
            ...pagination,
            current: pagination.current,
            // pageSize: pagination.pageSize,
        });
        // setCurrentPage(pagination.current)
        // console.log('set页码table', currentPage)
        // const { order } = sortedInfo;
        // let newOrdering = '-analysis__created_on';
        // if (order === 'ascend') {
        //     newOrdering = 'analysis__created_on';
        // } else {
        //     newOrdering = '-analysis__created_on';
        // }
        // setOrdering(newOrdering);
        // fetchData()
    };

    useEffect(() => {
        const isStaff = sessionStorage.getItem('isStaff');
        console.log('taskmanager', isStaff)
        if (!isStaff || isStaff === 'false') {
            return message.info('请用管理员账号登录', 3, () => {
                // 这里写你希望在提示框关闭后执行的代码
                go('/login')
            });
        }
        fetchData();
    }, [pagination.current]);

    const columns = [
        {
            title: 'analysis_id',
            dataIndex: 'analysis_id',
        },
        {
            title: 'target',
            dataIndex: 'target',
            ellipsis: true,
        },
        {
            title: 'sha256',
            dataIndex: 'sha256',
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'state',
        },
        {
            title: '家族',
            dataIndex: 'family',
        },
        {
            title: '标签',
            dataIndex: 'tag',
        },
        {
            title: '提交时间',
            dataIndex: 'submit_time',
            key: 'submit_time',
            render: (value) => formatDate(value),
        },
        {
            title: '恶意分数',
            dataIndex: 'score',
            key: 'score',
        },
    ];

    const handleSearchChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleRangePickerChange = (dates) => {
        // console.log('handleRangePickerChange', dates)
        const [startDate, endDate] = dates;
        setCreatedOnStartValue(startDate.format('YYYY-MM-DDTHH:mm:ss[Z]'));
        setCreatedOnEndValue(endDate.format('YYYY-MM-DDTHH:mm:ss[Z]'));
        setRangeValue(dates)
    };

    const handleChangeScoreStart = (event) => {
        setScoreStartValue(event.target.value);
    };
    const handleChangeScoreEnd = (event) => {
        setScoreEndValue(event.target.value);
    };

    const handleFamilyChange = (value) => {
        setSelectedFamily(value);
    };

    const handleTagChange = (value) => {
        setSelectedTag(value);
    };

    const handleSubmit = async (values) => {
        // this.setState({ createdOn: values.createdOn, score: values.score, family: values.family, tag: values.tag }, () => {
        //     this.fetchTasks(1);
        // });
        console.log('Success:', values);
        setPagination({
            ...pagination,
            current: 1,
        });
        fetchData();

    };

    return (
        <div style={{ padding: '24px', flex: 1 }}>
            <Form layout="inline" onFinish={handleSubmit} style={{ marginBottom: '24px' }}>
                <Form.Item label="文件Hash" name="search">
                    <Input
                        placeholder="搜索文件Hash (SHA256)"
                        onPressEnter={handleSubmit}
                        allowClear
                        onChange={handleSearchChange}
                    />
                </Form.Item>
                <Form.Item label="提交时间" name="createdOn">
                <RangePicker allowClear={false} showTime
              format="YYYY/MM/DD HH:mm:ss" style={{ width: '240px' }} onChange={handleRangePickerChange} />
                </Form.Item>
                <Form.Item label="恶意分数" name="score">
                    <Input.Group compact>
                        <Input style={{ width: 90, textAlign: 'center' }} placeholder="最低分" onChange={handleChangeScoreStart} />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                                backgroundColor: 'white',
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Input
                            style={{
                                width: 90,
                                textAlign: 'center',
                                borderLeft: 'none',
                            }}
                            placeholder="最高分"
                            onChange={handleChangeScoreEnd}
                        />
                    </Input.Group>
                </Form.Item>
                <Form.Item label="家族" name="selectedFamily">
                    <Select
                        placeholder="选择家族"
                        value={selectedFamily}
                        onChange={handleFamilyChange}
                        style={{ width: 200 }}
                        allowClear
                        showSearch
                        filterOption={true}
                    >
                        {familyOptions.map((option) => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="标签">
                    <Select
                        placeholder="选择标签"
                        value={selectedTag}
                        onChange={handleTagChange}
                        style={{ width: 200 }}
                        allowClear
                    >
                        {tagOptions.map((option) => (
                            <Option key={option} value={option}>{option}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        查询
                    </Button>
                </Form.Item>
            </Form>
            <Space>
                <Button type="primary" onClick={handleDeleteBatch}>批量删除</Button>
                <Button type="primary" onClick={handleReanalyzeBatch}>批量重新分析</Button>
                <Button type="primary" onClick={handleBanReanalysis}>禁止重新分析</Button>
                <Button type="primary" onClick={handleUnbanReanalysis}>允许重新分析</Button>
            </Space>
            <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data.map(item => ({
                    ...item,
                    key: item.id,
                }))}
                onChange={handleSorterChange}
                pagination={pagination}
            />
        </div>
    );
};

export default TaskManage;