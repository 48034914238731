import SubmitFile from '../SubmitFile'
import Analysis from "../pages/Analysis";
import User from "../pages/User";
import Login from "../pages/Login";
import NotFound from "../pages/404";
import Register from '../pages/Register';
import SearchPage from '../pages/SearchPage';
import SearchTargetPage from '../pages/SearchTargetPage';
import TaskCenter from '../pages/TaskCenter';
import TaskManage from '../pages/TaskManage';
import RoleManage from '../pages/RoleManage';
import PermissionManage from '../pages/PermissionManage';
import SetUserRole from '../pages/SetUserRole';
import List from '../List';
import Detail from '../Detail';


export default [
    { path: '/', name: 'Home', component: SubmitFile },
    { path: '/analysis', name: 'Analysis', component: Analysis },
    { path: '/user', name: 'User', component: User },
    { path: '/login', name: 'Login', component: Login },
    { path: '/register', name: 'Register', component: Register },
    { path: '/404', name: '404', component: NotFound },
    { path: '/search', name: 'SearchTargetPage', component: SearchTargetPage },
    { path: '/list', name: 'List', component: List },
    { path: '/task', name: 'TaskCenter', component: TaskCenter, auth: true },
    { path: '/detail/:id', name: 'Detail', component: Detail },
    { path: '/news', name: 'News', component: SearchPage, },
    { path: '/upload', name: 'SubmitFile', component: SubmitFile, },
    { path: '/admin/task', name: 'TaskManage', component: TaskManage, },
    { path: '/admin/permission', name: 'PermissionManage', component: PermissionManage, },
    { path: '/admin/role', name: 'RoleManage', component: RoleManage, },
    { path: '/admin/user', name: 'SetUserRole', component: SetUserRole, },
]