// AuthContext.js
import React, { createContext, useState, useEffect } from 'react'
import { createHashHistory } from 'history'
import axios from '../api/index'
import { go, decodeToken } from '../utils';

let timer = null

// 初始化状态，这里可以根据实际情况调整，比如从localStorage读取
const initialState = {
  isAuthenticated: !!sessionStorage.getItem('ACCESS_TOKEN'),
  user: null,
  login: (userData) => { },
  logout: () => { },
};

export const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const history = createHashHistory()
  useEffect(() => {
    // 这里可以添加逻辑，比如从服务器验证token，然后更新isAuthenticated的状态
  }, []);

  function start() {
    // console.log('start')
    timer && clearInterval(timer)

    timer = setInterval(() => {
      handleTokenRefresh(history)
    }, 5000)
  }

  const Token = getToken()
  // jwt解析refrsh token
  if (Token) {
    const decodedToken = decodeToken(Token);
    console.log('authcontext解码', decodedToken)
    sessionStorage.setItem('isStaff', String(decodedToken.is_staff))
  }
  
  const refreshToken = getRefreshToken()
  if (Token && refreshToken) {
    start()
  }

  const login = (userData) => {
    // 更新用户认证状态和用户信息
    setState(prevState => ({
      ...prevState,
      isAuthenticated: true,
      user: userData,
    }));
    // 可以在这里保存token到localStorage或其他存储
    sessionStorage.setItem('username', userData)
    start()
  };
  const logout = () => {
    // 清除用户认证状态和用户信息
    setState({
      isAuthenticated: false,
      user: null,
    });
    goLogin()
    // 清理token等
    deleteToken()
  };

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

const ACCESS_TOKEN = "ACCESS_TOKEN"
const REFRESH_TOKEN = "REFRESH_TOKEN"

function setToken(access_token, refresh_token) {
  sessionStorage.setItem(ACCESS_TOKEN, access_token)
  sessionStorage.setItem(REFRESH_TOKEN, refresh_token)
}

function getToken() {
  return sessionStorage.getItem(ACCESS_TOKEN)
}

function getRefreshToken() {
  return sessionStorage.getItem(REFRESH_TOKEN)
}

function deleteToken() {
  sessionStorage.removeItem(ACCESS_TOKEN)
  sessionStorage.removeItem(REFRESH_TOKEN)
  sessionStorage.removeItem('username')
  sessionStorage.removeItem('isStaff')
}

function decode(token) {
  let splitCode = token.split(".");
  let str = splitCode[1]
  let info = JSON.parse(decodeURIComponent(escape(window.atob(str))));
  return info
}

function handleTokenRefresh(history) {
  // jwt解析refrsh token
  const Token = getToken()
  // jwt解析refrsh token
  const refreshToken = getRefreshToken()
  if (!Token || !refreshToken) {
    console.log("token is null")
    goLogin()
    return
  }
  const now = Math.floor(Date.now() / 1000)
  const timestamp = decode(Token).exp
  const timestampRefresh = decode(refreshToken).exp
  // access token 过期，但refresh token 未过期，刷新access token
  if (timestampRefresh >= now && timestamp < now) {
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/token/refresh/', {
      refresh: refreshToken
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }).then(({ status, data: { access } }) => {
      if (status == 200) {
        setToken(access, refreshToken)
      }
    }).catch(() => {
      deleteToken()
      goLogin()
    })
  }

  // access token 过期，refresh token 过期， 退出并跳转到登录页
  if (timestampRefresh < now) {
    deleteToken()
    goLogin()
  }
}

function goLogin() {
  go('/login')
  timer && clearInterval(timer)
}

export {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  AuthProvider,
  setToken,
  getToken,
  getRefreshToken,
  deleteToken,
  decode,
  handleTokenRefresh
}