import React, { useState, useEffect } from 'react';
import { Table, message } from 'antd';
import axios from '../api/index';
import { URLRolePermissions } from '../api/config';
import { go } from '../utils';

const PermissionManage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const isStaff = sessionStorage.getItem('isStaff');
        if (isStaff === 'false') {
            return message.info('请用管理员账号登录', 3, () => {
                // 这里写你希望在提示框关闭后执行的代码
                go('/login')
            });
        }
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(URLRolePermissions); // 假设这是你的API路径
            setData(response.data);
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
        },
        {
            title: '标签',
            dataIndex: 'label',
        },
    ];

    return (
        <div style={{ flex: 1 }}>
            <Table
                columns={columns}
                dataSource={data.map(item => ({
                    ...item,
                    key: item.name,
                }))}
            />
        </div>
    );
};

export default PermissionManage;