
// @ts-nocheck
import React, { Component } from "react";
import {
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { Carousel, Image } from "antd";
import { URLScreenshot } from '../api/config'
import axios from '../api/index'
import { handleDownload } from '../api/index'
import '../css/screenshot.css'

export class Screenshot extends Component<any, any> {
    carouselRef = React.createRef();

    constructor(props) {
        super(props);
        // console.log('props', props)
        this.state = {
            id: props.id || '',
            task_id: props.task_id || '',
            screenshot: props.screenshot || [],
            imageUrls: [],
        };
    }

    async componentDidUpdate(prevProps) {
        // console.log('props', prevProps, this.props)
        // 检查props.signatures是否发生了变化
        if (prevProps.screenshot !== this.props.screenshot) {
            // 如果发生变化，更新state
            this.setState({ screenshot: this.props.screenshot }, async () => {
                try {
                    const screenshots = this.state.screenshot;
                    const promises = screenshots.map(screenshot => {
                        // 构建完整的URL路径
                        const url = `/analysis/${this.props.id}/task/${this.props.task_id}/screenshot/${screenshot.name}`;
                        // 发起获取截图的请求
                        return axios.get(url, {
                            responseType: 'blob',
                        });
                    });

                    // 使用Promise.all并发处理所有请求
                    const images = await Promise.all(promises);

                    // 这里`images`是一个包含所有成功获取到的截图数据（如Blob）的数组
                    // 你可以根据需要处理这些数据，比如更新state来存储或显示这些图片

                    // 示例：简单打印Blob大小（或做其他处理）
                    const imageUrls = images.map((image, index) => {
                        // console.log(`Screenshot ${screenshots[index].name} fetched, size: ${image.size}`);
                        return URL.createObjectURL(image.data)
                    });
                    this.setState({ imageUrls: imageUrls });
                } catch (error) {
                    console.error("Error fetching screenshots:", error);
                    // 处理错误情况
                }
            });

        }
    }
    componentDidMount() {
        const { screenshot, id, task_id } = this.props;
        // console.log('componentDidMount screenshot', screenshot, id, task_id)
    }

    handleLeft = () => {
        if (this.carouselRef.current) {
            this.carouselRef.current.prev();
        }
    }
    handleRight = () => {
        if (this.carouselRef.current) {
            this.carouselRef.current.next();
        }
    }

    render() {
        const file_path = process.env.REACT_APP_FILE_PATH
        const { screenshot, id, task_id } = this.props;
        // console.log('screenshot', screenshot, id, task_id, this.state.imageUrls)
        const imageElements = this.state.imageUrls?.map((url, index) => (
            <div key={index} className="screenshot-container">
                <Image src={url} alt={`Screenshot ${index}`} style={{ objectFit: 'cover', width: '100%', }} />
            </div>
        ));
        const Left = <div className="arrow-left"><LeftOutlined /></div>
        return (
            <div className="run-images">
                {/* <div className="header">
                    <span className="header-name">运行截图</span>
                    <span className="english-name">Running screenshot</span>
                </div> */}
                <LeftOutlined className="arrow arrow-left" onClick={this.handleLeft} />
                <RightOutlined className="arrow arrow-right" onClick={this.handleRight} />
                <Carousel ref={this.carouselRef} className="custom_carousel" dots={{ className: 'custom-dots' }}>
                    {/* {screenshot?.map((item: { path: string | undefined; ocr: string | "未识别"; }, key: any) => {
                        if (item.path === undefined)
                            return <span />
                        const fp = file_path + item.path
                        return (
                            <img key={item.name + key} src={fp} alt={item.ocr} style={{ textAlign: "center", width: "100%" }} />
                        )
                    })} */}

                    {imageElements}
                </Carousel>
            </div>
        );
    }

}