import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';

export const formatDate = (date, formatStr = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return '';
  }
  
  // 如果传入的是日期字符串，先尝试转换为dayjs对象
  if (typeof date === 'string') {
    date = dayjs(date);
  }
  
  // 如果date不是dayjs对象，尝试转换为Date对象
  if (!(date instanceof dayjs)) {
    try {
      date = dayjs(new Date(date));
    } catch (error) {
      console.error('Invalid date provided. Could not format it.', error);
      return '';
    }
  }

  return date.format(formatStr);
};

function go(path: string) {
  window.location.href = window.location.origin + `/#${path}`;
}

function decodeToken(token: string): any {
  try {
      const decoded = jwtDecode(token);
      return decoded;
  } catch (error) {
      console.error('Error decoding token:', error);
      return null;
  }
}

export {
  go, decodeToken
}